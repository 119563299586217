import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import * as firebase from 'firebase';
import {Observable} from 'rxjs';
import {User} from '../models/user.model';
import {FormGroup, FormBuilder, Validators, FormArray} from '@angular/forms';
import {ValidationError} from 'ajv';
import {AngularFirestore} from '@angular/fire/firestore';


@Component({
    selector: 'app-userform',
    templateUrl: './userform.component.html',
    styleUrls: ['./userform.component.css']
})
export class UserformComponent implements OnInit {

    userProfile: FormGroup;
    state: string;
    user: Observable<User>;
    offices: any[] = [];

    constructor(private db: AngularFirestore, private fb: FormBuilder, public auth: AuthService) {
    }

    ngOnInit() {
        this.db.collection('offices', ref => ref.orderBy('name')).valueChanges().subscribe(data => {
            console.log(data);
            this.offices = data;
        });

        this.userProfile = this.fb.group({
            firstName: ['', Validators.required],
            middleName: ['', Validators.required],
            lastName: ['', Validators.required],
            suffix: ['', Validators.required],
            email: ['', Validators.required],
            phoneExt: ['', Validators.required],
            officePhone: ['', Validators.required],
            phoneNumber: ['', Validators.required],
            jobTitle: ['', Validators.required],
            graphOffice: ['', Validators.required],
            phones: this.fb.array([]),
            preferred: ['', Validators.required],
            startDate: ['', Validators.required],
        });
    }
    addPhone() {
        const phone = this.fb.group({
            type: [],
            number: [],
        });

        this.phoneForms.push(phone);
    }

    deletePhone(i) {
        this.phoneForms.removeAt(i);
    }

    get phoneForms() {
        return this.userProfile.get('phones') as FormArray;
    }
    changeHandler(e) {
        this.state = e;
    }
    alert() {
        alert('button works');
    }

}
