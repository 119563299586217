import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {auth} from 'firebase';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {User} from '../models/user.model';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    user: Observable<User>;

    constructor(
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router
    ) {
        this.user = this.afAuth.authState.pipe(
            switchMap(user => {
                if (user) {
                    console.log('LOGGED IN USER: ' + JSON.stringify(user));
                    return this.afs.doc<User>('users/' + user.uid).valueChanges();
                } else {
                    return of(null);
                }
            })
        );
    }

    async microsoftSignin() {
        const provider = new auth.OAuthProvider('microsoft.com');
        provider.setCustomParameters({
            tenant: 'd86fc206-8d96-450d-a4e8-8805d7af0eb3'
        });
        const credential = await this.afAuth.auth.signInWithPopup(provider);
        console.log('Logged in with Microsoft: ' + JSON.stringify(credential));
        this.updateUserData(credential);
        return this.router.navigate(['/']);
    }

    async signOut() {
        console.log('User signed out');
        await this.afAuth.auth.signOut();
        return this.router.navigate(['/login']);
    }

    async updateUserData(credential) {
        const user = credential.user;
        const graph = credential.additionalUserInfo.profile;
        const uid = user.uid;
        const userRef: AngularFirestoreDocument<User> = this.afs.doc<User>('users/' + uid);
        console.log('Saving user data for: ' + uid);
        const data = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            phoneNumber: graph.mobilePhone,
            graphID: graph.id,
            graphOffice: graph.officeLocation,
            firstName: graph.givenName,
            lastName: graph.surname,
            jobTitle: graph.jobTitle,
            graphToken: credential.credential.accessToken
        };
        return userRef.set(data, {merge: true});
    }

}

