import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild} from '@angular/router';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import {map, tap} from 'rxjs/operators';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private auth: AuthService, private router: Router) {
    }

    canActivate(next, state): Observable<boolean> {
        return this.auth.user.pipe(
            take(1),
            map(User => !!User), // <-- map to boolean
            tap(loggedIn => {
                if (!loggedIn) {
                    console.log('Access Denied');
                    this.router.navigate(['/login']);
                }
            })
        );
    }

    canActivateChild(next, state): Observable<boolean> {
        return this.auth.user.pipe(
            take(1),
            map(User => !!User), // <-- map to boolean
            tap(loggedIn => {
                if (!loggedIn) {
                    console.log('Access Denied');
                    this.router.navigate(['/login']);
                }
            })
        );
    }

}
