import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AppRoutingModule} from './app.routing';

import {FireFormDirective} from './services/fire-form.directive';
import { AppComponent } from './app.component';
import {LoginComponent} from './login/login.component';
import {UserformComponent} from './userform/userform.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import * as firebase from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyB0MkddOIXLJgfSaVwAmRS1iWVH-lDP4cQ',
    authDomain: 'dgoc-d8deb.firebaseapp.com',
    databaseURL: 'https://dgoc-d8deb.firebaseio.com',
    projectId: 'dgoc-d8deb'
};
@NgModule({
  declarations: [
      AppComponent,
      UserformComponent,
      LoginComponent,
      FireFormDirective,
  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(firebaseConfig), // imports firebase/app needed for everything
        AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
        AngularFirestoreModule,
        AngularFireStorageModule,
        AppRoutingModule,
        FormsModule,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
