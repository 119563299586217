import {
    Directive,
    Input,
    Output,
    EventEmitter,
    HostListener,
    OnInit,
    OnDestroy
} from '@angular/core';
import {
    AngularFirestore,
    AngularFirestoreDocument
} from '@angular/fire/firestore';
import {FormControl, FormGroup} from '@angular/forms';
import {tap, map, take, debounceTime} from 'rxjs/operators';
import {Subscription} from 'rxjs';


@Directive({
    selector: '[fireForm]'
})
export class FireFormDirective implements OnInit, OnDestroy {
    @Input() path: string;
    @Input() formGroup: FormGroup;

    private _state: 'loading' | 'synced' | 'modified' | 'error';

    @Output() stateChange = new EventEmitter<string>();
    @Output() formError = new EventEmitter<string>();

    private docRef: AngularFirestoreDocument;

    private formSub: Subscription;

    constructor(private afs: AngularFirestore) {
    }

    ngOnInit() {
        this.preloadData();
        this.autoSave();
    }

    set state(val) {
        this._state = val;
        this.stateChange.emit(val);
    }

    async setDoc() {
        try {
            await this.docRef.set(this.formGroup.value, {merge: true});
            this.state = 'synced';
        } catch (err) {
            console.log(err);
            this.formError.emit(err.message);
            this.state = 'error';
        }
    }

    preloadData() {
        this.state = 'loading';
        this.docRef = this.getDocRef(this.path);
        this.docRef
            .valueChanges()
            .pipe(
                tap(doc => {
                    if (doc) {
                        this.formGroup.patchValue(doc);
                        this.formGroup.markAsPristine();
                        this.state = 'synced';
                    }
                }),
                take(1)
            )
            .subscribe();
    }

    autoSave() {
        this.formSub = this.formGroup.valueChanges
            .pipe(
                tap(change => {
                    this.state = 'modified';
                }),
                debounceTime(2000),
                tap(change => {
                    if (this.formGroup.valid && this._state === 'modified') {
                        this.setDoc().then(r => r);
                    }
                })
            )
            .subscribe();
    }

    @HostListener('ngSubmit', ['$event'])
    onSubmit(e) {
        this.setDoc().then(r => r);
    }

    getDocRef(path: string): any {
        if (path.split('/').length % 2) {
            return this.afs.doc('${path}/${this.afs.createId()}');
        } else {
            return this.afs.doc(path);
        }
    }

    ngOnDestroy(): void {
        this.formSub.unsubscribe();
    }


}
